import PropTypes from "prop-types";
import React from "react";
import clsx from "clsx";
import { navigate } from "react-big-calendar/lib/utils/constants";

class CustomToolbar extends React.Component {
    render() {
        let {
            localizer: { messages },
            label
        } = this.props;

        let now = new Date();
        let nowPlus = new Date(new Date().setDate(new Date().getDate() + 31))

        return (
            <div className="rbc-toolbar">
                <span className="rbc-btn-group">
                    <button
                        type="button"
                        onClick={this.navigate.bind(null, navigate.TODAY)}
                    >
                        {messages.today}
                    </button>
                    <button
                        type="button"
                        onClick={this.navigate.bind(null, navigate.PREVIOUS)}
                        disabled={now >= this.props.date ? "disabled" : ""}
                    >
                        {messages.previous}
                    </button>
                    <button
                        type="button"
                        onClick={this.navigate.bind(null, navigate.NEXT)}
                        disabled={nowPlus <= this.props.date ? "disabled" : ""}

                    >
                        {messages.next}
                    </button>
                </span>

                <span className="rbc-toolbar-label">{label}</span>

                <span className="rbc-btn-group">
                    {this.viewNamesGroup(messages)}
                </span>
            </div>
        );
    }

    navigate = action => {
        this.props.onNavigate(action);
    };

    view = view => {
        this.props.onView(view);
    };

    viewNamesGroup(messages) {
        let viewNames = this.props.views;
        const view = this.props.view;

        if (viewNames.length > 1) {
            return viewNames.map(name => (
                <button
                    type="button"
                    key={name}
                    className={clsx({ "rbc-active": view === name })}
                    onClick={this.view.bind(null, name)}
                >
                    {messages[name]}
                </button>
            ));
        }
    }
}

CustomToolbar.propTypes = {
    view: PropTypes.string.isRequired,
    views: PropTypes.arrayOf(PropTypes.string).isRequired,
    label: PropTypes.node.isRequired,
    localizer: PropTypes.object,
    onNavigate: PropTypes.func.isRequired,
    onView: PropTypes.func.isRequired
};

export default CustomToolbar;
