import React, { useState } from "react";
import ReactDOM from "react-dom";

import "./content-card-styles.scss";

import { TRUNCATE } from "../../helpers/truncate";

const ContentCard = ({ date, pic, text, title, url, category }) => (
    <div className="col-lg-4">
        <div className="box-shadow content-card scale-11-hover">
            <a
                style={{ textDecoration: "none" }}
                href={category === 1 ? "/hirek/" + url : "/blog/" + url}
            >
                <img
                    className="content-card-pic"
                    src={"/pictures/" + pic.filename}
                    alt={pic.alt}
                />
                <div className="col-12 fs-16 c-szim-blue font-os-normal bold-text content-card-title">
                    {title}
                </div>
                <div
                    className="content-card-text font-os-light c-szim-grey"
                    dangerouslySetInnerHTML={{ __html: TRUNCATE(text, 150) }}
                ></div>
                <div className="bold-text content-card-date c-szim-mid-blue">
                    {date}
                </div>
            </a>
        </div>
    </div>
);

if (document.getElementById("content-card")) {
    const component = document.getElementById("content-card");
    const props = Object.assign({}, component.dataset);
    ReactDOM.render(
        <ContentCard {...props} />,
        document.getElementById("content-card")
    );
}

export default ContentCard;
