/**
 * First we will load all of this project's JavaScript dependencies which
 * includes React and other helpers. It's a great starting point while
 * building robust, powerful web applications using React + Laravel.
 */

require('./bootstrap');

/**
 * Next, we will create a fresh React component instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */

require('./components/timetable/timetable.init');
require('./components/navbar-dynamics/navbar-dynamics.component');
require('./components/szim-carousel/szimcarousel.component');
require('./components/szim-dynamic-banner/szim-dynamic-banner.component');
require('./components/content-card-container/content-card-container.component');
require('./components/subpage-content-card-container/subpage-content-card-container.component');
require('./components/hamburger-menu/hamburger.component');

// custom functions

window.setMobileTable = (selector) => {
    if (document.querySelectorAll(selector).length > 0) {
        const tableEl = document.querySelector(selector);
        const thEls = tableEl.querySelectorAll('thead th');
        const tdLabels = Array.from(thEls).map(el => el.innerText);
        tableEl.querySelectorAll('tbody tr').forEach(tr => {
            Array.from(tr.children).forEach(
                (td, ndx) => {
                    td.setAttribute('label', tdLabels[ndx])
                    if (td.childNodes.length === 0) {
                        td.className = "hide-me"
                    }
                }
            );
        });
    }
}

setMobileTable(".csoportos");
setMobileTable(".egyeni")