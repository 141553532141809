import zsani from "../../../images/zsani.png";
import viki from "../../../images/viki.png";
import gabor from "../../../images/gabor.png";

const exampleDataModel = [
    {
        pic: viki,
        alt: "viki",
        name: "Ringer Viktória",
        text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer rhoncus maximus scelerisque. Nunc eu eros in tellus mattis luctus. Proin vestibulum risus at erat porttitor venenatis. Duis urna enim, ornare nec rhoncus non, rutrum eu mi. Donec tristique sollicitudin felis, a interdum dui pretium quis. Duis sit amet orci et est posuere malesuada. Maecenas id sapien lacus. Donec feugiat leo at ipsum dictum, sit amet semper ex euismod. Phasellus eget nibh facilisis, venenatis risus nec, sagittis tortor. Pellentesque porta sollicitudin dolor eget cursus. "

    },
    {
        pic: gabor,
        alt: "gabor",
        name: "Kovács Gábor",
        text: "Integer rhoncus maximus scelerisque. Nunc eu eros in tellus mattis luctus. Proin vestibulum risus at erat porttitor venenatis. Duis urna enim, ornare nec rhoncus non, rutrum eu mi. Donec tristique sollicitudin felis, a interdum dui pretium quis. Duis sit amet orci et est posuere malesuada. Maecenas id sapien lacus. Donec feugiat leo at ipsum dictum, sit amet semper ex euismod. Phasellus eget nibh facilisis, venenatis risus nec, sagittis tortor. Pellentesque porta sollicitudin dolor eget cursus."

    },
    {
        pic: zsani,
        alt: "zsani",
        name: "Hornyák Zsanett",
        text: "Nunc quis venenatis nulla. Nunc in eros at urna mollis viverra ut sit amet nibh. Suspendisse eu vehicula mi, vel commodo arcu. Nam metus ipsum, imperdiet aliquet est et, viverra iaculis risus. Curabitur sit amet pretium diam. Fusce sed nibh tincidunt, commodo lorem ut, cursus ex. Duis posuere erat non maximus ultricies. Aliquam elit mi, gravida sed suscipit sit amet, ornare sed risus. Ut pharetra nibh urna, ac fermentum ligula tempor interdum. Nulla vestibulum volutpat metus sit amet dictum."
    }
];

export default exampleDataModel;
