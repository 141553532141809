import React, { useState } from "react";
import ReactDOM from "react-dom";
import {
    exampleBlogArticles,
    exampleNews
} from "./subpage-content-card-container.example.data";
import {
    trainers,
    individualTrainings,
    groupTrainings
} from "../../examples.data";
import SubpageContentCard from "./content-card/content-card.component";
import "./subpage-content-card-container.styles.scss";

const SubpageContentCardContainer = ({ containerType, articles }) => {
    const articlesObj = JSON.parse(articles);
    console.log(articlesObj);
    return (
        <div className="container content-card-container">
            <div className="row justify-content-around row-cols-lg-3">
                {(function() {
                    switch (containerType) {
                        case "articles":
                            return articlesObj.map(function({
                                id,
                                description,
                                updated_at,
                                title,
                                picture_id,
                                alt,
                                url
                            }) {
                                return (
                                    <SubpageContentCard
                                        key={id}
                                        type={containerType}
                                        pic={picture_id}
                                        text={description}
                                        date={updated_at}
                                        alt={alt}
                                        title={title}
                                        url={url}
                                    ></SubpageContentCard>
                                );
                            });
                        case "group-lessons":
                            return groupTrainings.map(function({
                                index,
                                name,
                                description,
                                price,
                                trainer,
                                link,
                                pic,
                                alt
                            }) {
                                return (
                                    <SubpageContentCard
                                        key={index}
                                        type={containerType}
                                        pic={pic}
                                        text={description}
                                        alt={alt}
                                        title={name}
                                        link={link}
                                        price={price}
                                        trainer={trainer}
                                    ></SubpageContentCard>
                                );
                            });
                        case "individual-treatment":
                            return individualTrainings.map(function({
                                index,
                                name,
                                description,
                                price,
                                trainer,
                                link,
                                pic,
                                alt
                            }) {
                                return (
                                    <SubpageContentCard
                                        key={index}
                                        type={containerType}
                                        pic={pic}
                                        text={description}
                                        alt={alt}
                                        title={name}
                                        link={link}
                                        price={price}
                                        trainer={trainer}
                                    ></SubpageContentCard>
                                );
                            });
                        case "trainers":
                            return trainers.map(function({
                                index,
                                about,
                                user,
                                schools,
                                trainings
                            }) {
                                return (
                                    <SubpageContentCard
                                        key={index}
                                        type={containerType}
                                        pic={user.pic}
                                        text={about}
                                        schools={schools}
                                        alt={user.alt}
                                        firstName={user.firstName}
                                        lastName={user.lastName}
                                    ></SubpageContentCard>
                                );
                            });
                        default:
                            return null;
                    }
                })()}
            </div>
        </div>
    );
};

if (document.getElementById("subpage-content-card-container")) {
    const component = document.getElementById("subpage-content-card-container");
    const props = Object.assign({}, component.dataset);
    ReactDOM.render(
        <SubpageContentCardContainer {...props} />,
        document.getElementById("subpage-content-card-container")
    );
}

export default SubpageContentCardContainer;
