import React from "react";

const CustomAgendaEvent = ({ event, handleReservationAg }) => {
    return (
        <div className="row">
            <div className="col-sm-4">
                <b>{event.title}</b>
            </div>
            <div className="col-sm-2">
                {!event.waiting_list_free_places
                    ? "Helyek: "
                    : "Várakozólista: "}
                {!event.waiting_list_free_places
                    ? event.free_spaces
                    : event.waiting_list_free_places}
            </div>
            <div className="col-sm-3">{event.instructor} <br/> <span className="small">{event.room}</span></div>
            <div className="col-sm-3">
                <span
                    onClick={() => {
                        handleReservationAg(event);
                        updateSelectedAg(event);
                    }}
                    className={`sbtn sm ${
                        event.status[0] === 3 ?
                            "sbtn-danger" : (event.status[0] === 2 ?
                                "sbtn-warning" : "sbtn-primary")}`}
                >

                    {event.status[0] === 3 ? "✖ " + event.status[1] : event.status[1]}
                </span>
            </div>
        </div>
    );
};

export default CustomAgendaEvent;
