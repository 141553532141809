import React, { useState, useEffect, useRef } from "react";
import axios from "axios";

import { momentLocalizer } from "react-big-calendar";
import globalize from "globalize";
import moment from 'moment';
require("globalize/lib/cultures/globalize.culture.hu-HU");
const localizer = momentLocalizer(moment)
import "./timetable.styles.scss";
import "react-big-calendar/lib/css/react-big-calendar.css";

import Calendar from "./timtable.calendar";
import TimeTableCard from "./timetable-card/timetable-card.component";
import CustomAgenda from "./timetable.agenda";
import CustomToolbar from "./timetable.toolbar";

import useDeviceDetect from "../helpers/deviceDetect";

const TimeTable = ({
    getSetRes,
    apiPath,
}) => {

    const [events, setEvents] = useState(false);
    const [selectedEvent, setSelectedEvent] = useState(false);
    const [response, setResponse] = useState([]);
    const [visible, setVisible] = useState("");
    const [visibleEvent, setVisibleEvent] = useState("");
	const { isMobile } = useDeviceDetect();

    const eventCard = useRef(null);

    const handleSelected = () => {
        const eventToUpdate = events.find(x => x.id === selectedEvent.id);
        const eventWithMessage = {
            ...eventToUpdate,
            message: response
        };
        setSelectedEvent(eventWithMessage);
    };

    const updateSelected = () => {
        const selectedToUpdate = events.find(x => x.id === selectedEvent.id);
        const selectedWithMessage = {
            ...selectedToUpdate,
            status: selectedToUpdate.status
        };
        setSelectedEvent(selectedWithMessage);
    };

    const handleReservation = () => {
        const redirectUrl = selectedEvent.status[2];
        const cond = redirectUrl === "/profil" || redirectUrl === "/login" || redirectUrl === "/araink"
        if (cond) {
            return window.location.href = apiPath + redirectUrl
        } else if (selectedEvent && response) {
            return getSetRes(
                apiPath + redirectUrl,
                setResponse,
                "response")
        }
    }

    const showMessage = () => {
        if (response[1]) {
            setVisible("active");
            setTimeout(() => {
                setVisible("");
            }, 6000);
        }

    };

    const fetchData = async () => {
        await axios
            .get(apiPath + "/get-all-lessons")
            .then(response => {
                const responseEvents = response.data["group_lessons"].map(group_lesson => {
                    return {
                        id: group_lesson.id,
                        title: group_lesson.title,
                        start: new Date(group_lesson.start),
                        end: new Date(group_lesson.end),
                        free_spaces: group_lesson.free_spaces,
                        instructor: group_lesson.instructor,
                        room: group_lesson.room,
                        status: group_lesson.status
                    };
                });
                setEvents(responseEvents);
            })
            .catch(error => {
                console.log(error);
            });
    };

    useEffect(() => {
        fetchData();
        showMessage();
    }, [response]);

    useEffect(() => {
        selectedEvent ? handleSelected() : null
        selectedEvent && response ? updateSelected() : null;
        return () => { };
    }, [events]);

    const handleClose = () => {
        setVisibleEvent("");
    };

    return (
        <>
            <div className="col-sm-12 col-md-12">
                {!events ? (
                    <div className="loader"><div></div><div></div><div></div></div>
                ) : (
                    <Calendar
                        views={{
                            day: true,
                            agenda: CustomAgenda,
                            week: true,
                            month: true
                        }}
                        format={"YYYY/MM/DD HH:mm"}
                        timeGutterFormat="HH:mm"
                        culture="hu-HU"
                        localizer={localizer}
                        components={{ toolbar: CustomToolbar }}
                        events={events}
                        startAccessor="start"
                        endAccessor="end"
                        defaultView={isMobile ? "day" : "week"}
                        dayLayoutAlgorithm={"no-overlap"}
                        timeslots={1}
                        step={60}
                        style={{ height: "700px" }}
                        min={new Date(0, 0, 0, 8, 0, 0)}
                        max={new Date(0, 0, 0, 19, 0, 0)}
                        onSelectEvent={event => {
                            setSelectedEvent(event);
                            setVisibleEvent("visible");
                        }}
                        popup={true}
                        messages={{
                            today: "Ma",
                            previous: "❮",
                            next: "❯",
                            month: "Hónap",
                            week: "Hét",
                            day: "Nap",
                            agenda: "Lista nézet",
                            date: "Dátum",
                            time: "Idő",
                            event: "Óra"
                        }}
                    />
                )}
            </div>
            <div ref={eventCard} className={"over" + " " + visibleEvent}>
                {!selectedEvent ? (
                    ""
                ) : (
                    <>
                        <div
                            onMouseUp={handleClose}
                            className={"close-btn"}
                        >×</div>
                        <TimeTableCard
                            selectedEvent={selectedEvent}
                            handleReservation={handleReservation}
                            response={response}
                        />
                    </>
                )}
            </div>
            <div
                onMouseUp={handleClose}
                className={"backdrop" + " " + visibleEvent}
            ></div>
            <div className={"popup" + " " + visible}>
                <p>{response[1]}</p>
            </div>
        </>
    );
};

export default TimeTable;
