export const carouselData = [
    {
        title: "Egyéni kezeléseink bemutatása",
        text: "A Szimmetria Gyógytorna Stúdió erőt ad és mozgásba hoz. Tekintsd meg óráinkat, foglalj kedvedre és élvezd az egészséget!",
        linkText: "EGYÉNI KEZELÉSEK",
        link: "/egyeni-kezelesek"
    },
    {
        title: "Csoportos óráink bemutatása",
        text: "A Szimmetria Gyógytorna Stúdió erőt ad és mozgásba hoz. Tekintsd meg óráinkat, foglalj kedvedre és élvezd az egészséget!",
        linkText: "CSOPORTOS ÓRÁK",
        link: "/csoportos-orak"
    },
    {
        title: "Tekintsd meg óráinkat",
        text: "A Szimmetria Gyógytorna Stúdió erőt ad és mozgásba hoz. Tekintsd meg óráinkat, foglalj kedvedre és élvezd az egészséget!",
        linkText: "ÓRAREND",
        link: "/orarend"
    },
    {
        title: "Tetszeni fognak áraink",
        text: "A Szimmetria Gyógytorna Stúdió erőt ad és mozgásba hoz. Tekintsd meg óráinkat, foglalj kedvedre és élvezd az egészséget!",
        linkText: "ÁRAINK",
        link: "/araink"
    },
    {
        title: "Elérhetőségienk oldala",
        text: "A Szimmetria Gyógytorna Stúdió erőt ad és mozgásba hoz. Tekintsd meg óráinkat, foglalj kedvedre és élvezd az egészséget!",
        linkText: "KAPCSOLAT",
        link: "/kapcsolat"
    }
];

export const bannerPics = {
    backgroundPic: "./images/bg.png",
    frontPic: "./images/group.png",
};

export const logoPic = {
    logoPic: "./images/szimmetria_logo_no_text.png"
}
