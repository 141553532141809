import React from 'react'
import ReactDOM from 'react-dom';
import NavbarButton from './navbar-button/navbar-button.component'
import './navbar-dynamics.styles.scss'

const NavbarDynamics = ({login}) => (
    <div className="row navbar-row justify-content-between align-content-center">
        <NavbarButton position="first" login={login} url={login ? "/logout" : "/login" } title={ login ? "Kijelentkezés" : "Bejelentkezés" }/>
        <NavbarButton position="second" login={login} url={ login ? "/profil" : "/register" } title={ login ? "Profilom" : "Regisztráció" }/>
    </div>
);

if (document.getElementById('navbar-dynamics')) {
    const component = document.getElementById('navbar-dynamics');
    const props = Object.assign({}, component.dataset);
    ReactDOM.render(<NavbarDynamics {...props} />, document.getElementById('navbar-dynamics'));
}

export default NavbarDynamics;

