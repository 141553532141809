
const kincsoPic = require("../images/kincsoProfile.jpg");
const vikiPic = require("../images/vikiProfile.jpg");
const gaborPic = require("../images/gaborProfile.jpg");
const zsaniPic = require("../images/zsaniProfile.jpg");
const csoportos = require("../images/group_lessons.jpg");
const egyeni = require("../images/egyeni.jpg");

const roles = {
    SUPERADMIN: "superadmin",
    ADMIN: "admin",
    USER: "user"
};

export const users = {
    superAdmins: [
        {
            firstName: "György",
            lastName: "Németh",
            role: roles.SUPERADMIN,
            pic: kincsoPic,
            alt: "alt text"
        },
        {
            firstName: "Viktória",
            lastName: "Ringer",
            role: roles.SUPERADMIN,
            pic: vikiPic,
            alt: "alt text"
        }
    ],
    admins: [
        {
            firstName: "Kincső",
            lastName: "Kálmán",
            role: roles.ADMIN,
            pic: kincsoPic,
            alt: "alt text"
        },
        {
            firstName: "Zsanett",
            lastName: "Nagy",
            role: roles.ADMIN,
            pic: zsaniPic,
            alt: "alt text"
        },
        {
            firstName: "Gábor",
            lastName: "Balogh",
            role: roles.ADMIN,
            pic: gaborPic,
            alt: "alt text"
        },
    ],
    users: [
        {
            firstName: "Lajos",
            lastName: "Kovács",
            role: roles.USER,
        },
        {
            firstName: "Katalin",
            lastName: "Pető",
            role: roles.USER,
        }
    ],
};

export const individualTrainings = [
    {
        name: "Állapotfelmérés",
        description: "Első alkalommal egy állapotfelmérésen vehetsz részt, mely során alaposan kikérdezünk, megvizsgálunk (felmérjük a problémás terület mozgástartományát, a környező izmok erejét), megnézzük, hogy az adott szegmens hogyan viselkedik működés/funkció közben, majd a vizsgálati eredmények és a panaszok alapján felállítjuk a funkcionális diagnózist/diagnózisokat. Ez kifejezetten a gyógytornászok feladata, nem konkrét kórképet diagnosztizálunk, hanem a mozgató szervrendszerben kialakult állapotra utalunk ezzel a kifejezéssel, melyből tudni fogjuk, hogy mik lesznek a rövid és hosszú távú kezelési céljaink, tervünk. Ha szükséges, tape ragasztásával is kiegészítjük ezt az alkalmat.",
        link: "localhost:3000/egyeni/allapotfelmeres",
        price: "11.000 Ft",
        trainer: [users.superAdmins[1]],
        pic: egyeni,
        alt: 'alt text'
    },
    {
        name: "McKenzie terápia",
        description: "A McKenzie terápia egy személyre szabott vizsgálati és kezelési módszer, melyet a világ 40 országában használnak gyógytornászok, illetve orvosok. Egy alapos vizsgálattal indul, mely során a beteg bizonyos mozdulat ismétlésével (vagy éppen kitartott helyzetben) figyeli a tünetre gyakorolt hatást. Ez tehát nem egy olyan eljárás, amelyben a páciens passzívan fekszik az asztalon, hanem nagyon is aktívan részt vesz a kezelés menetében. Sok esetben kerültek már el páciensek a Robin McKenzie féle metódusnak köszönhetően gerincsérv műtétet. Célunk vele nem csak a panasz megszűntetése, hanem kiújulásának megelőzése is, melyet a mozgásterápiával együtt személyre szabva biztosítunk minden kedves Vendégünknek, akinek szüksége van rá.",
        link: "localhost:3000/egyeni/mckenzie",
        price: "9.000 Ft",
        trainer: [users.admins[1]],
        pic: egyeni,
        alt: 'alt text'

    },
    {
        name: "Manuálterápia",
        description: "A manuál terápia kézzel végzett diagnosztikus és terápiás fogások összessége, melyek a mozgató szervrendszer visszafordítható elváltozásainak és rendellenességeinek diagnosztizálására és kezelésére szolgálnak.\n" +
            "Ezen terápiás eljárást csak erre jogosult és képzett (diplomával rendelkező) szakember végezhet, orvos vagy gyógytornász személyében. Első alkalommal mindig az anamnézis alapos felvételére és a jelenlegi mozgásszervi állapot felmérésére kerül sor, megkeresve a tartó és mozgató rendszer kóros elváltozásait. A diagnózis felállítását követően manuálisan végzett terápiás fogásokkal történik a kezelés.\n" +
            "\n" +
            "Milyen esetekben javasolt?\n" +
            "\n" +
            "– gerincproblémák\n" +
            "– tartási rendellenességek (hanyag tartás)\n" +
            "– hát- és derékfájdalom\n" +
            "– nyak- és vállfájdalom\n" +
            "– csípő- térdfájdalom\n" +
            "– egyéb ízületi problémák, fájdalmak\n" +
            "– sportsérülések (húzódás, rándulás, stb.)",
        link: "localhost:3000/egyeni/manual",
        price: "12.000 Ft",
        trainer: [users.admins[0]],
        pic: egyeni,
        alt: 'alt text'
    },
    {
        name: "FDM",
        description: "FDM=Fascia Disztorziós Modell. A Fascia/kötőszövet az egész testünket átszövő, izmokat-ìnakat-csontokat-ereket-idegeket összekötő szövet. Sérülése (pl. túlterhelés, hirtelen erőbehatás) különböző fájdalmakat, mozgásbeszűkülést okoz. Az ilyen következményekkel járó kötöszöveti elváltozásokat nevezzük disztorziónak. Az FDM jelenleg a legsikeresebb mozgásszervi fájdalomcsillapìtó metódus, mozgásbeszűkülés kezelésére alkalmazható manuális terápia.\n" +
            "Foglalkozásvezető: Kálmán Kincső\n" +
            "\n",
        link: "localhost:3000/egyeni/fdm",
        price: "10.000 Ft",
        trainer: [users.admins[2]],
        pic: egyeni,
        alt: 'alt text'
    },
];

export const groupTrainings = [
    {
        name: "Alakformáló csípőtorna",
        description: "Ha eleged van abból, hogy pár guggolástól megfájdul a térded, vagy a derekad, akkor ezen az órán van a helyed, itt ugyanis gyógytornász vezetésével ízületkímélően fejlődhetsz! Megerősítjük a csípőízület körüli izmokat (farizmokat, combizmokat), stabilitást adva a térdeknek és a csípőknek. Bemelegítés után fekvő helyzetekben kezdünk analitikusabb gyakorlatokkal (amikor csak 1 izomcsoport dolgozik), majd pedig állásban folytatjuk funkcionálisabb, bonyolultabb, több izomcsoportot megdolgoztató feladattal. Az ízületekre gyakorolt jótékony hatáson túl (stabilizálás) nem mellékesen még feszesítjük, kerekítjük is a feneket.",
        link: "localhost:3000/egyeni/allapotfelmeres",
        price: "11.000 Ft",
        trainer: [users.superAdmins[1]],
        pic: csoportos,
        alt: 'alt text'
    },
    {
        name: "Core tréning",
        description: "Napjainkban nagyon divatos kifejezéssé vált ez a fajta mozgás, a plank gyakorlatot is majd’ mindenki ismeri, de mit is takar pontosan ez az egész? A core izmok testünk központi izmai, a hasüreget veszik körül, a medencénket és a gerincünket stabilizálják. Nevükön nevezve őket:\n" +
            "\n" +
            "egyenes hasizom\n" +
            "gerincfeszítő izmok\n" +
            "külső és belső ferde hasizom\n" +
            "haránt hasizom\n" +
            "a medencefenék izmai\n" +
            "rekeszizom\n" +
            "Hozzájuk tartozó további izmok:\n" +
            "\n" +
            "nagy farizom\n" +
            "széles hátizom\n" +
            "trapéz izom\n" +
            "Ezen izmokra koncentrálva adunk a testnek nem csak egy jó alakot, de egy erős, stabil törzset is. Akik ezen az órán részt vesznek, megtanulhatják hogyan kell helyesen kivitelezni azt a bizonyos plank helyzetet. Ha ez már megvan, tovább nehezítjük eszközökkel, illetve ebből a helyzetből kiinduló gyakorlatokkal verejtékmirigyeket nem kímélve",
        link: "localhost:3000/group/core",
        price: "9.000 Ft",
        trainer: [users.admins[0]],
        pic: csoportos,
        alt: 'alt text'
    },
    {
        name: "Stretching",
        description: "Ízületeink, beleértve a gerincünket alkotó csigolyák közti kisízületeket is a folyamatos rossz testhelyzetben való terhelés (ülés, görnyedés) miatt nem megfelelő pozícióban rögzülnek. Ez hosszú távon ahhoz vezet, hogy az ízületeket áthidaló izmok tónusa és lefutása is megváltozik tovább erősítve a rossz helyzetet, elindítva egy ördögi kört. Ezen az órán ezt a kört megtörve szeretnénk újra megadni az izmoknak a megfelelő tónust, mely által az ízületeket a teljes mozgáspályájukon tudják majd mozgatni. Miután innen kijöttél, ha gumiember nem is, de mindenképp légiesen mozgó, testileg, lelkileg feltöltött leszel.",
        link: "localhost:3000/group/stretch",
        price: "12.000 Ft",
        trainer: [users.admins[1], users.superAdmins[1]],
        pic: csoportos,
        alt: 'alt text'
    },
    {
        name: "Funkcionális gerinctréning\n",
        description: "Gerincünk funkciója a tartás, mely manapság, valljuk be, szinte senkinél nem mondható szépnek, főleg a számítógép előtt ülve. Ezt a tartást szeretnénk ezen óránkon szebbé varázsolni, méghozzá tartó izmaink erősítésével. A fekvő helyzeteket elfelejtheti, aki ide betéved, nap közben sem fekszünk, nem úgy terheljük a gerincünket. Pont emiatt vertikális testhelyzeteket választva dolgoztatjuk meg többek közt a felületes és a mély hátizmokat is. A fáradtság és az izomláz garantált.",
        link: "localhost:3000/group/functional",
        price: "10.000 Ft",
        trainer: [users.admins[0]],
        pic: csoportos,
        alt: 'alt text'
    },
    {
        name: "Alakformáló csípőtorna",
        description: "Ha eleged van abból, hogy pár guggolástól megfájdul a térded, vagy a derekad, akkor ezen az órán van a helyed, itt ugyanis gyógytornász vezetésével ízületkímélően fejlődhetsz! Megerősítjük a csípőízület körüli izmokat (farizmokat, combizmokat), stabilitást adva a térdeknek és a csípőknek. Bemelegítés után fekvő helyzetekben kezdünk analitikusabb gyakorlatokkal (amikor csak 1 izomcsoport dolgozik), majd pedig állásban folytatjuk funkcionálisabb, bonyolultabb, több izomcsoportot megdolgoztató feladattal. Az ízületekre gyakorolt jótékony hatáson túl (stabilizálás) nem mellékesen még feszesítjük, kerekítjük is a feneket.",
        link: "localhost:3000/egyeni/allapotfelmeres",
        price: "11.000 Ft",
        trainer: [users.superAdmins[1]],
        pic: csoportos,
        alt: 'alt text'
    },
    {
        name: "Core tréning",
        description: "Napjainkban nagyon divatos kifejezéssé vált ez a fajta mozgás, a plank gyakorlatot is majd’ mindenki ismeri, de mit is takar pontosan ez az egész? A core izmok testünk központi izmai, a hasüreget veszik körül, a medencénket és a gerincünket stabilizálják. Nevükön nevezve őket:\n" +
            "\n" +
            "egyenes hasizom\n" +
            "gerincfeszítő izmok\n" +
            "külső és belső ferde hasizom\n" +
            "haránt hasizom\n" +
            "a medencefenék izmai\n" +
            "rekeszizom\n" +
            "Hozzájuk tartozó további izmok:\n" +
            "\n" +
            "nagy farizom\n" +
            "széles hátizom\n" +
            "trapéz izom\n" +
            "Ezen izmokra koncentrálva adunk a testnek nem csak egy jó alakot, de egy erős, stabil törzset is. Akik ezen az órán részt vesznek, megtanulhatják hogyan kell helyesen kivitelezni azt a bizonyos plank helyzetet. Ha ez már megvan, tovább nehezítjük eszközökkel, illetve ebből a helyzetből kiinduló gyakorlatokkal verejtékmirigyeket nem kímélve",
        link: "localhost:3000/group/core",
        price: "9.000 Ft",
        trainer: [users.admins[0]],
        pic: csoportos,
        alt: 'alt text'
    },
    {
        name: "Stretching",
        description: "Ízületeink, beleértve a gerincünket alkotó csigolyák közti kisízületeket is a folyamatos rossz testhelyzetben való terhelés (ülés, görnyedés) miatt nem megfelelő pozícióban rögzülnek. Ez hosszú távon ahhoz vezet, hogy az ízületeket áthidaló izmok tónusa és lefutása is megváltozik tovább erősítve a rossz helyzetet, elindítva egy ördögi kört. Ezen az órán ezt a kört megtörve szeretnénk újra megadni az izmoknak a megfelelő tónust, mely által az ízületeket a teljes mozgáspályájukon tudják majd mozgatni. Miután innen kijöttél, ha gumiember nem is, de mindenképp légiesen mozgó, testileg, lelkileg feltöltött leszel.",
        link: "localhost:3000/group/stretch",
        price: "12.000 Ft",
        trainer: [users.admins[1], users.superAdmins[1]],
        pic: csoportos,
        alt: 'alt text'
    },
    {
        name: "Funkcionális gerinctréning\n",
        description: "Gerincünk funkciója a tartás, mely manapság, valljuk be, szinte senkinél nem mondható szépnek, főleg a számítógép előtt ülve. Ezt a tartást szeretnénk ezen óránkon szebbé varázsolni, méghozzá tartó izmaink erősítésével. A fekvő helyzeteket elfelejtheti, aki ide betéved, nap közben sem fekszünk, nem úgy terheljük a gerincünket. Pont emiatt vertikális testhelyzeteket választva dolgoztatjuk meg többek közt a felületes és a mély hátizmokat is. A fáradtság és az izomláz garantált.",
        link: "localhost:3000/group/functional",
        price: "10.000 Ft",
        trainer: [users.admins[0]],
        pic: csoportos,
        alt: 'alt text'
    },
];


export const trainers = [
    {
        user: users.superAdmins[1],
        about: "Diplomámat a Debreceni Egyetem Orvos és Egészségügyi Centrumának Népegészségügyi Karán szereztem. 4 éves koromban megismerkedtem a jazz balett világával, amit 12 év után egy sport sérülést követően kénytelen voltam abba hagyni, ami pedig segített visszatérni az aktív mozgáshoz, az a gyógytorna volt. Nagyon szép szakma a miénk, de sajnos eddigi tapasztalataim alapján azzal szembesülök, hogy a legtöbben nem tudják, hogy mennyire szerteágazó is ez a tudomány. A Szimmetria Gyógytorna Stúdió beindításával többek közt erre is fel szeretném hívni a figyelmet, tudatosítva minden kedves Vendégben, hogy a megelőzéstől a kezelésen keresztül a rehabilitációig mindenhol ott vagyunk, nem beszélve a fitness világáról, melyből egészségügyi ismereteink által ízületkímélően tudjuk kivenni a részünket.\n" +
            "\n" +
            "Egyetemi éveim során ismerkedtem meg a kineziológiai tape-el, amit azóta is napi szinten használok, remekül kiegészíti a mozgásterápiát. Fitball oktatóként a fitness világába kalauzolt az élet, nagyon vidám és egyben nagyon trükkös eszköz, majdnem minden órán találkozhatnak majd vele Vendégeink. A munka világában pedig rájöttem, hogy sajnos rengeteg gerinc problémával küzdő embertársam van, akiknek a kezelésében nagyon sokat segít a világszerte elismert McKenzie terápia. Manuális gerinckorrekciós képzést (Dorn terápia és Breuss masszázs) pedig azért szerettem volna mindenképp tanulni, hogy oldani tudjam a gerincünk különböző szegmensein kialakuló csigolya blokkokat.",
        schools: [
            "Kinematic Taping",
            "McKenzie A, B kurzus",
            "Dorn Terápia és Breuss Masszázs",
            "Funkcionális vizsgálat",
            "Fitball a fitnessben",
            "SMR henger (Fascia lazítás helye az orvosi rehabilitációban)",
        ],
        trainings: [
            {
                name: individualTrainings[0].name,
                link: individualTrainings[0].link
            },
            {
                name: individualTrainings[1].name,
                link: individualTrainings[1].link
            },
            {
                name: groupTrainings[0].name,
                link: individualTrainings[0].link
            },
        ]
    },
    {
        user: users.admins[1],
        about: "Képesítésemet 2013-ban szereztem gyógytornász-fizioterapeuta szakirányon a Semmelweis Egyetem Egészségtudományi Karán. Főállásban a Magyar Honvédség Egészségügyi Központ II. számú telephelyén dolgozom reumatológia osztályon, illetve gyógytorna szakambulancián. Ennek köszönhetően ágyéki-, háti- és nyaki gerinc eredetű fájdalmak, alsó- és felső végtagba sugárzó panaszok, illetve ízületi problémák kezelésében is van jártasságom. Kezeléseim irányadója a McKenzie – Mechanikai Diagnózis és Terápia. A módszer aktív részvételt kíván a vendégek oldaláról, nem célja a páciens-terapeuta függő viszony kiépítése. Kítűzésem ez által a mihamarabbi gyógyulás elérése és az önállóan végezhető fejlődés/szinten tartás megtanítása.",
        schools: [
            "Kinezio tape – proprioceptív physiotape",
            "McKenzie – Mechanikai Diagnózis és Terápia A-,B-,C-,D modul",
            "FDM terápia",
        ],
        trainings: [
            {
                name: individualTrainings[2].name,
                link: individualTrainings[2].link
            },
            {
                name: individualTrainings[0].name,
                link: individualTrainings[0].link
            },
            {
                name: groupTrainings[1].name,
                link: individualTrainings[1].link
            },
        ]
    },
    {
        user: users.admins[0],
        about: "A Debreceni Egyetem Népegészségügyi Karán végeztem gyógytornász-fizioterapeutaként. Jelenleg az Országos Reumatológiai és Fizioterápiás Intézetben dolgozom. A munkám során lehetőségem nyílt tapasztalatot szerezni a reumatológiai eredetű mozgásszervi panaszok fizioterápiájában.\n" +
            "Tanulmányaim során elvégeztem a Kinematic Tape kurzus alapfokozatát, valamint funkcionális gátizom tréning tanfolyamon is részt vettem.  \n" +
            " Az ülőmunka, a mozgásszegény életmód okozta mozgásszervi panaszok egyre több embert érintenek, ezért véleményem szerint nagy hangsúlyt kell fordítani a preventív gyógytornára. Specifikus gyakorlatokkal, korrigált helyzetben végzett tornával  a mozgásszervi problémák megelőzésére törekszünk. A túlhasznált izmok nyújtásával, a gyengült izmok erősítésével, a megfelelő mozgástartományban maradva, javíthatóak az ízületek élettani funkciói.\n" +
            "Bizalommal fordulhat hozzám mindenki, aki mozogni, gyógyulni szeretne.\n" +
            "Várok mindenkit szeretettel!\n" +
            "\n",
        schools: [
            "Kinematic taping",
            "McKenzie A",
            "Evidence based medicine a medencefenék fizioterápiájában (gátizom torna)",
            "Gravity tréner",
            "SMR system alap + extra képzés (izompólya lazítás hengerrel)",
            "3D Scoliosis Terápia Tanfolyam I-II\n",
        ],
        trainings: [
            {
                name: individualTrainings[1].name,
                link: individualTrainings[1].link
            },
            {
                name: individualTrainings[0].name,
                link: individualTrainings[0].link
            },
            {
                name: groupTrainings[3].name,
                link: individualTrainings[3].link
            },
        ]
    },
]

export const rooms = [
    {
        name: "Funkcionális"
    },
    {
        name: "Labdás terem"
    },
    {
        name: "Rendelő"
    },
]

export const exampleDays = [
    {
        index: 1,
        appointments: [
            {
                name: "Gerinckondicionáló gyógytorna",
                room: rooms[0],
                time: "08:00 - 08:50",
                freeSpace: 4,
                trainer: users.admins[0]
            },
            {
                name: "Alakformáló csípőtorna",
                room: rooms[1],
                time: "08:00 - 08:50",
                trainer: users.admins[1]
            },
            {
                name: "Manuális gyógytorna",
                room: rooms[2],
                time: "08:00 - 08:50",
                freeSpace: 1,
                trainer: users.admins[1]
            },
        ],
        date: new Date(2020, 8, 29),

    },
    {
        index: 2,
        appointments: [
            {
                name: "Gerinckondicionáló gyógytorna",
                room: rooms[2],
                time: "08:00 - 08:50",
                freeSpace: 4,
                trainer: users.admins[1]
            },
            {
                name: "Alakformáló csípőtorna",
                room: rooms[1],
                time: "08:00 - 08:50",
                trainer: users.admins[0]
            },
            {
                name: "Manuális gyógytorna",
                room: rooms[2],
                time: "08:00 - 08:50",
                freeSpace: 1,
                trainer: users.admins[0]
            },
        ],
        date: new Date(2020, 8, 28),
    },
    {
        index: 3,
        appointments: [
            {
                name: "Gerinckondicionáló gyógytorna",
                room: rooms[2],
                time: "08:00 - 08:50",
                freeSpace: 4,
                trainer: users.admins[1]
            },
            {
                name: "Alakformáló csípőtorna",
                room: rooms[1],
                time: "08:00 - 08:50",
                trainer: users.admins[0]
            },
            {
                name: "Manuális gyógytorna",
                room: rooms[2],
                time: "08:00 - 08:50",
                freeSpace: 1,
                trainer: users.admins[0]
            },
        ],
        date: new Date(2020, 8, 27),
    },
    {
        index: 4,
        appointments: [
            {
                name: "Gerinckondicionáló gyógytorna",
                room: rooms[2],
                time: "08:00 - 08:50",
                freeSpace: 4,
                trainer: users.admins[1]
            },
            {
                name: "Alakformáló csípőtorna",
                room: rooms[1],
                time: "08:00 - 08:50",
                trainer: users.admins[0]
            },
            {
                name: "Manuális gyógytorna",
                room: rooms[2],
                time: "08:00 - 08:50",
                freeSpace: 1,
                trainer: users.admins[0]
            },
        ],
        date: new Date(2020, 8, 26),
    },
    {
        index: 5,
        appointments: [
            {
                name: "Gerinckondicionáló gyógytorna",
                room: rooms[2],
                time: "08:00 - 08:50",
                freeSpace: 4,
                trainer: users.admins[1]
            },
            {
                name: "Alakformáló csípőtorna",
                room: rooms[1],
                time: "08:00 - 08:50",
                trainer: users.admins[0]
            },
            {
                name: "Manuális gyógytorna",
                room: rooms[2],
                time: "08:00 - 08:50",
                freeSpace: 1,
                trainer: users.admins[0]
            },
        ],
        date: new Date(2020, 8, 25),
    },
    {
        index: 6,
        appointments: [
            {
                name: "Gerinckondicionáló gyógytorna",
                room: rooms[2],
                time: "08:00 - 08:50",
                freeSpace: 4,
                trainer: users.admins[1]
            },
            {
                name: "Alakformáló csípőtorna",
                room: rooms[1],
                time: "08:00 - 08:50",
                trainer: users.admins[0]
            },
            {
                name: "Manuális gyógytorna",
                room: rooms[2],
                time: "08:00 - 08:50",
                freeSpace: 1,
                trainer: users.admins[0]
            },
        ],
        date: new Date(2020, 8, 24),
    },
    {
        index: 7,
        appointments: [
            {
                name: "Gerinckondicionáló gyógytorna",
                room: rooms[2],
                time: "08:00 - 08:50",
                freeSpace: 4,
                trainer: users.admins[1]
            },
            {
                name: "Alakformáló csípőtorna",
                room: rooms[1],
                time: "08:00 - 08:50",
                trainer: users.admins[0]
            },
            {
                name: "Manuális gyógytorna",
                room: rooms[2],
                time: "08:00 - 08:50",
                freeSpace: 1,
                trainer: users.admins[0]
            },
        ],
        date: new Date(2020, 8, 23),
    },
    {
        index: 8,
        appointments: [
            {
                name: "Gerinckondicionáló gyógytorna",
                room: rooms[2],
                time: "08:00 - 08:50",
                freeSpace: 4,
                trainer: users.admins[1]
            },
            {
                name: "Alakformáló csípőtorna",
                room: rooms[1],
                time: "08:00 - 08:50",
                trainer: users.admins[0]
            },
            {
                name: "Manuális gyógytorna",
                room: rooms[2],
                time: "08:00 - 08:50",
                freeSpace: 1,
                trainer: users.admins[0]
            },
        ],
        date: new Date(2020, 8, 22),
    },
];

