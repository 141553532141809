import React, { useState } from "react";
import ReactDOM from "react-dom";
import DOMPurify from "dompurify";

import Carousel from "nuka-carousel";

import { useInView } from "react-hook-inview";

import "./szimcarousel.styles.scss";
import exampleData from "./szimcarousel.example.data";

const SzimCarousel = ({ instructors }) => {
    const [currCar, setCurrCar] = useState({ slideIndex: 0 });
    const [sect, isVisible] = useInView({
        threshold: 0.5
    });

    const instructorsObj = JSON.parse(instructors);

    return (
        <section className="szim-carousel" ref={sect}>
            <div className="container">
                <div className="row">
                    <div className="col-md-12 col-sm-12 col-xs-12 fs-36 font-os-heavy bold-text p-b-30 szim-carousel-title">
                        <h2>MUNKATÁRSAINK</h2>
                    </div>
                </div>
                <div className="row brk-rev">
                    <div className="col-12 szim-carousel-text fs-30 bold-text font-os-heavy">
                        <div className="row">
                            <div className="col-12 col-sm-6 col-lg-3 szim-carousel-name">
                                <Carousel
                                    slideIndex={currCar.slideIndex}
                                    afterSlide={slideIndex =>
                                        setCurrCar({ slideIndex })
                                    }
                                    withoutControls={true}
                                    autoplay={false}
                                    heightMode={"current"}
                                    transitionMode="scroll"
                                    wrapAround={false}
                                    autoplayInterval={2000000}
                                >
                                    {instructorsObj.map(worker => (
                                        <h4
                                            className="colleauge"
                                            key={worker.id}
                                        >
                                            {worker.last_name}{" "}
                                            {worker.first_name}
                                        </h4>
                                    ))}
                                </Carousel>
                            </div>
                            <div className="col-12 col-sm-6 col-lg-3 szim-carousel-button-container-second pb-5">
                                <button
                                    className="szim-carousel-button-second spin-second circle-second"
                                    onClick={slideIndex =>
                                        setCurrCar({
                                            slideIndex:
                                                currCar.slideIndex != 0
                                                    ? currCar.slideIndex - 1
                                                    : (instructorsObj.length)
                                        })
                                    }
                                >
                                    ❮
                                </button>
                                <button
                                    className="szim-carousel-button-second spin-second circle-second"
                                    onClick={slideIndex =>
                                        setCurrCar({
                                            slideIndex:
                                                currCar.slideIndex != (instructorsObj.length + 1 )
                                                    ? currCar.slideIndex + 1
                                                    : 0
                                        })
                                    }
                                >
                                    ❯
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6 col-sm-12 col-xs-12 szim-carousel-text slider-bordered">
                        <Carousel
                            slideIndex={currCar.slideIndex}
                            afterSlide={slideIndex =>
                                setCurrCar({ slideIndex })
                            }
                            withoutControls={true}
                            autoplay={false}
                            transitionMode="scroll"
                            wrapAround={false}
                            heightMode={"current"}
                            autoplayInterval={2000}
                        >
                            {instructorsObj.map(worker => (
                                <div key={worker.id}>
                                    <p dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(worker.description)}}></p>
                                </div>
                            ))}
                        </Carousel>
                    </div>
                    <div className="col-lg-6 col-sm-12 col-xs-12 d-flex justify-content-end img">
                        <Carousel
                            slideIndex={currCar.slideIndex}
                            afterSlide={slideIndex =>
                                setCurrCar({ slideIndex })
                            }
                            withoutControls={true}
                            transitionMode="fade"
                            heightMode={"current"}
                            wrapAround={false}
                            autoplayInterval={2000}
                        >
                            {exampleData.map(worker => (
                                <img
                                    key={worker.alt}
                                    src={worker.pic}
                                    alt={worker.alt}
                                />
                            ))}
                        </Carousel>
                    </div>
                </div>
                <div className="slide-control"></div>
            </div>
        </section>
    );
};

if (document.getElementById("szim-carousel")) {
    const component = document.getElementById("szim-carousel");
    const props = Object.assign({}, component.dataset);
    ReactDOM.render(
        <SzimCarousel {...props} />,
        document.getElementById("szim-carousel")
    );
}

export default SzimCarousel;
