import React, {useState} from 'react'
import ReactDOM from 'react-dom';
import {SwitchTransition, CSSTransition} from "react-transition-group";
import ContentCard from './content-card/content-card.component'
import './content-card-container.styles.scss'

const ContentCardContainer = ({blog, news}) => {
    const newsObj = JSON.parse(news);
    const blogObj = JSON.parse(blog);
    const [option, setOption] = useState(0);
    const optionTitles = ["HÍREK ÉS ESEMÉNYEK", "BLOG"];

    function smoothScroll() {
        document.querySelectorAll('a[href^="#"]').forEach(anchor => {
            anchor.addEventListener('click', function (e) {
                e.preventDefault();

                document.querySelector(this.getAttribute('href')).scrollIntoView({
                    behavior: 'smooth'
                });
            });
        });
    }

    return (
        <div className="container content-card-container">
            <div className="row content-card-container-filter-row">
                <div className="col fs-18 c-szim-blue bold">
                    <a href="#content-card-container" onClick={() => setOption(0)}
                       className="content-card-container-option c-szim-blue">
                        {optionTitles[0]}
                    </a>
                    <a href="#content-card-container" onClick={() => setOption(1)}
                       className="content-card-container-option c-szim-blue">
                        {optionTitles[1]}
                    </a>
                    {smoothScroll()}
                </div>
            </div>
            <SwitchTransition>
                <CSSTransition key={ option ? 0 : 1}
                    addEndListener={(node, done) => node.addEventListener("transitionend", done, false)}
                    classNames='fade' >
                    <div className="row justify-content-around">
                        {(function () {
                            switch (option) {
                                case 0:
                                    return newsObj.map(function ({id, description, created_at, title, picture, url, article_category_id}) {
                                        return <ContentCard key={id} pic={picture} text={description}
                                                            category={article_category_id} date={created_at}
                                                            title={title} url={url}> </ContentCard>;
                                    });
                                case 1:
                                    return blogObj.map(function ({id, description, created_at, title, picture, url, article_category_id}) {
                                        return <ContentCard key={id} pic={picture} text={description}
                                                            category={article_category_id} date={created_at}
                                                            title={title} url={url}> </ContentCard>;
                                    });
                                default:
                                    return null;
                            }
                        })()
                        }
                    </div>
                </CSSTransition>
            </SwitchTransition>
            <div className="row justify-content-center p-t-60">
                <div className="col text-center">
                    <a href={option === 0 ? "/hirek" : "/blog"} className="sbtn sbtn-primary"><span
                       >ÖSSZES BEJEGYZÉS</span></a>
                </div>
            </div>
        </div>
    )
};

if (document.getElementById('content-card-container')) {
    const component = document.getElementById('content-card-container');
    const props = Object.assign({}, component.dataset);
    ReactDOM.render(<ContentCardContainer {...props} />, document.getElementById('content-card-container'));
}

export default ContentCardContainer;
