import React from 'react'
import ReactDOM from 'react-dom';
import './navbar-button.styles.scss'

const NavbarButton = ({title, login, position, url }) => (
    <a href={url} className= {`navbar-button
        ${login ? "navbar-button" : "navbar-button"}
        ${position === "first"  ? "navbar-button-ghost" : "navbar-button-full"}`} >
            { login && position === "second" ? <i className="fas fa-user"></i> : "" }
            { title }
            
    </a>
);

if (document.getElementById('navbar-button')) {
    const component = document.getElementById('navbar-button');
    const props = Object.assign({}, component.dataset);
    ReactDOM.render(<NavbarButton {...props} />, document.getElementById('navbar-button'));
}

export default NavbarButton;

// login ? "navbar-button no-border" : "navbar-button" + position = "first" ? "navbar-button-ghost" : "navbar-button-full"/**/
