import React from 'react'
import { slide as Menu } from 'react-burger-menu'
import "./hamburger.styles.scss"
import ReactDOM from "react-dom";

const HamburgerMenu = ({ login }) => {

    function showSettings(event) {
        event.preventDefault();
    }

    // NOTE: You also need to provide styles, see https://github.com/negomi/react-burger-menu#styling
    return (
        <Menu pageWrapId={"page-container"} outerContainerId={"hamburger-menu-outer"} right width={280}>
            <a id="hom" className="menu-item" href="/">FŐOLDAL</a>
            <a id="ind" className="menu-item" href="/egyeni-kezelesek">EGYÉNI KEZELÉSEK</a>
            <a id="groupl" className="menu-item" href="/csoportos-orak">CSOPORTOS ÓRÁK</a>
            <a id="timet" className="menu-item" href="/orarend">ÓRAREND</a>
            <a id="pri" className="menu-item" href="/araink">ÁRAINK</a>
            <a id="cont" className="menu-item" href="/kapcsolat">KAPCSOLAT</a>
            <a id="cont" className="menu-item" href="/munkatarsaink">MUNKATÁRSAINK</a>
            <a className="menu-item" href={login ? "/logout" : "/login"} title={login ? "Kijelentkezés" : "Bejelentkezés"}>{login ? "KIJELENTKEZÉS" : "BEJELENTKEZÉS"}</a>
            <a className="menu-item" href={login ? "/profil" : "/register"} title={login ? "Profilom" : "Regisztráció"}>{login ? "PROFILOM" : "REGISZTRÁCIÓ"}</a>

            <a id="tel" className="p-t-30 menu-item--small szim-hamburger-items p-tb-5-lr-10 fs-12" href="tel:+36703015187"> <i className="p-r-5 c-szim-light-blue fas fa-phone-alt"></i>
                +36/70-30-15-187</a>
            <a id="ml" className="menu-item--small szim-hamburger-items p-tb-5-lr-10 fs-12" href="mailto:info@szimmetriagyogytorna.hu"> <i className="p-r-5 c-szim-light-blue fas fa-envelope-open"></i>
                info@szimmetriagyogytorna.hu</a>
            <a id="addr" className="menu-item--small szim-hamburger-items p-tb-5-lr-10 fs-12" href="https://www.google.hu/maps/place/Szimmetria+Gy%C3%B3gytorna+St%C3%BAdi%C3%B3/@47.4875838,19.0602916,17z/data=!4m8!1m2!2m1!1s++++++++++++++++1093+Budapest,+G%C3%B6nczy+P%C3%A1l+ut.+4.%3C%2Fa%3E!3m4!1s0x4741dc5a9f3f0001:0x31414a212113eeeb!8m2!3d47.487621!4d19.060261"> <i className="p-r-5 c-szim-light-blue fas fa-map-marker-alt"></i>
                1093 Budapest, Gönczy Pál ut. 4.</a>
            {/*<a onClick={showSettings} className="menu-item--small" href="">Settings</a>*/}
        </Menu>
    )
};

if (document.getElementById('hamburger-menu')) {
    const component = document.getElementById('hamburger-menu');
    const props = Object.assign({}, component.dataset);
    ReactDOM.render(<HamburgerMenu {...props} />, document.getElementById('hamburger-menu'));
}

export default HamburgerMenu;
