import React, { useState } from "react";
import ReactDOM from "react-dom";
import DOMPurify from "dompurify";

import "./content-card-styles.scss";

const ContentCard = ({
    alt,
    date,
    pic,
    text,
    title,
    type,
    url,
    trainer,
    price
}) => {
    let pureText = DOMPurify.sanitize(text);
    let truncatedText = pureText.slice(0, 240) + "...";

    return (
        <div className="col p-15">
            <div className="box-shadow content-card p-t-0-b-30-lr-15">
                <div className="row">
                    {type !== "trainers" ? (
                        <div className="col-12 no-padding overflow-hidden">
                            <img
                                className="content-card-pic scale-11-hover"
                                src={pic}
                                alt={alt}
                            />
                        </div>
                    ) : (
                        <div className="col-12 no-padding overflow-hidden">
                            <img
                                className="content-card-pic scale-11-hover"
                                src={pic}
                                alt={alt}
                            />
                        </div>
                    )}
                    <div className="col-12 fs-16 c-szim-blue font-os-heavy bold-text content-card-title">
                        {title}
                    </div>
                    <div className="col-12 fs-12 content-card-text">
                        <p
                            dangerouslySetInnerHTML={{
                                __html: truncatedText
                            }}
                        ></p>
                    </div>
                </div>
                {type === "articles" ? (
                    <div className="row">
                        <div className="col-12 c-szim-blue font-os-heavy fs-8 bold-text content-card-date">
                            {date}
                        </div>
                    </div>
                ) : null}
                {type === "group-lessons" ||
                type === "individual-treatment" ||
                type === "articles" ? (
                    <div className="row">
                        <div className="col-12 text-center">
                            {type === "trainers" ? (
                                <a href={url ? "hirek/" + url : "#"}
                                className="sbtn sbtn-primary">
                                    <span>RÉSZLETEK</span>
                                </a>
                            ) : (
                                <a href={url ? "blog/" + url : "#"}
                                    className="sbtn sbtn-primary">
                                    <span>TOVÁBB OLVASOM</span>
                                </a>
                            )}
                        </div>
                    </div>
                ) : null}
            </div>
        </div>
    );
};
if (document.getElementById("content-card")) {
    const component = document.getElementById("content-card");
    const props = Object.assign({}, component.dataset);
    ReactDOM.render(
        <ContentCard {...props} />,
        document.getElementById("content-card")
    );
}

export default ContentCard;
