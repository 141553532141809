import PropTypes from "prop-types";
import React, { useRef, useEffect, useState } from "react";
import addClass from "dom-helpers/addClass";
import removeClass from "dom-helpers/removeClass";
import getWidth from "dom-helpers/width";
import scrollbarSize from "dom-helpers/scrollbarSize";

import * as dates from "react-big-calendar/lib/utils/dates";
import { navigate } from "react-big-calendar/lib/utils/constants";
import { inRange } from "react-big-calendar/lib/utils/eventLevels";
import { isSelected } from "react-big-calendar/lib/utils/selection";

import CustomAgendaEvent from "./timetable.agenda.event";

function CustomAgenda({
    selected,
    getters,
    accessors,
    localizer,
    components,
    length,
    date,
    events
}) {
    const headerRef = useRef(null);
    const dateColRef = useRef(null);
    const timeColRef = useRef(null);
    const contentRef = useRef(null);
    const tbodyRef = useRef(null);

    const [eventsAg, setEvents] = useState(events);

    const [visibleAg, setVisibleAg] = useState("");
    const [responseAg, setResponseAg] = useState([]);

    const apiPathAg = window.location.origin;

    const showMessageAg = () => {
        if (responseAg[1]) {
            setVisibleAg("active");
            setTimeout(() => {
                setVisibleAg("");
            }, 6000);
        }

    };

    const getSetResAg = (url, setter, data) => {
        axios
            .get(url)
            .then(response => {
                setter(response.data[data]);
            })
            .catch(error => {
                console.log(error);
            });
    };

    const handleReservationAg = eventAg => {
        const redirectUrl = eventAg.status[2]
        const cond = redirectUrl === "/profil" || redirectUrl === "/login" || redirectUrl === "/araink"
        if (cond) {
            return window.location.href = apiPathAg + redirectUrl
        }
        else if (eventAg && responseAg) {
            return getSetResAg(
                apiPathAg + redirectUrl,
                setResponseAg,
                "response")
        }
    };

    useEffect(() => {
        fetchData();
        showMessageAg();
    }, [responseAg])

    const fetchData = async () => {
        await axios
            .get(apiPathAg + "/get-all-lessons")
            .then(response => {
                let responseEvents = response.data["group_lessons"].map(group_lesson => {
                    return {
                        id: group_lesson.id,
                        title: group_lesson.title,
                        start: new Date(group_lesson.start),
                        end: new Date(group_lesson.end),
                        free_spaces: group_lesson.free_spaces,
                        instructor: group_lesson.instructor,
                        room: group_lesson.room,
                        status: group_lesson.status
                    };
                });
                responseEvents = responseEvents.filter(event => inRange(event, date, end, accessors));
                responseEvents.sort((a, b) => +accessors.start(a) - +accessors.start(b));

                setEvents(responseEvents);
            })
            .catch(error => {
                console.log(error);
            });
    };

    useEffect(() => {
        _adjustHeader();
    });

    const renderDay = (day, eventsAg, dayKey) => {
        const { event: Event, date: AgendaDate } = components;

        eventsAg = eventsAg.filter(e =>
            inRange(
                e,
                dates.startOf(day, "day"),
                dates.endOf(day, "day"),
                accessors
            )
        );

        return eventsAg.map((event, idx) => {
            let title = accessors.title(event);
            let end = accessors.end(event);
            let start = accessors.start(event);

            const userProps = getters.eventProp(
                event,
                start,
                end,
                isSelected(event, selected)
            );

            let dateLabel =
                idx === 0 && localizer.format(day, "agendaDateFormat");
            let first =
                idx === 0 ? (
                    <td
                        rowSpan={eventsAg.length}
                        className="rbc-agenda-date-cell"
                    >
                        {AgendaDate ? (
                            <AgendaDate day={day} label={dateLabel} />
                        ) : (
                            dateLabel
                        )}
                    </td>
                ) : (
                    false
                );

            return (
                <tr
                    key={dayKey + "_" + idx}
                    className={userProps.className}
                    style={userProps.style}
                >
                    {first}
                    <td className="rbc-agenda-time-cell">
                        {timeRangeLabel(day, event)}
                    </td>
                    <td className="rbc-agenda-event-cell">
                        <CustomAgendaEvent
                            event={event}
                            handleReservationAg={handleReservationAg}
                            apiPathAg={apiPathAg}
                        />
                    </td>
                </tr>
            );
        }, []);
    };

    const timeRangeLabel = (day, event) => {
        let labelClass = "",
            TimeComponent = components.time,
            label = localizer.messages.allDay;

        let end = accessors.end(event);
        let start = accessors.start(event);

        if (!accessors.allDay(event)) {
            if (dates.eq(start, end)) {
                label = localizer.format(start, "agendaTimeFormat");
            } else if (dates.eq(start, end, "day")) {
                label = localizer.format(
                    { start, end },
                    "agendaTimeRangeFormat"
                );
            } else if (dates.eq(day, start, "day")) {
                label = localizer.format(start, "agendaTimeFormat");
            } else if (dates.eq(day, end, "day")) {
                label = localizer.format(end, "agendaTimeFormat");
            }
        }

        if (dates.gt(day, start, "day")) labelClass = "rbc-continues-prior";
        if (dates.lt(day, end, "day")) labelClass += " rbc-continues-after";

        return (
            <span className={labelClass.trim()}>
                {TimeComponent ? (
                    <TimeComponent event={event} day={day} label={label} />
                ) : (
                    label
                )}
            </span>
        );
    };

    const _adjustHeader = () => {
        if (!tbodyRef.current) return;

        let header = headerRef.current;
        let firstRow = tbodyRef.current.firstChild;

        if (!firstRow) return;

        let isOverflowing =
            contentRef.current.scrollHeight > contentRef.current.clientHeight;

        let _widths = [];
        let widths = _widths;

        _widths = [
            getWidth(firstRow.children[0]),
            getWidth(firstRow.children[1])
        ];

        if (widths[0] !== _widths[0] || widths[1] !== _widths[1]) {
            dateColRef.current.style.width = _widths[0] + "px";
            timeColRef.current.style.width = _widths[1] + "px";
        }

        if (isOverflowing) {
            addClass(header, "rbc-header-overflowing");
            header.style.marginRight = scrollbarSize() + "px";
        } else {
            removeClass(header, "rbc-header-overflowing");
        }
    };

    let { messages } = localizer;
    let end = dates.add(date, length, "day");
    let range = dates.range(date, end, "day");

    return (
        <>
            <div className="rbc-agenda-view">
                {eventsAg ? (
                    <React.Fragment>
                        <table ref={headerRef} className="rbc-agenda-table">
                            <thead>
                                <tr>
                                    <th className="rbc-header" ref={dateColRef}>
                                        {messages.date}
                                    </th>
                                    <th className="rbc-header" ref={timeColRef}>
                                        {messages.time}
                                    </th>
                                    <th className="rbc-header">
                                        {messages.event}
                                    </th>
                                </tr>
                            </thead>
                        </table>
                        <div className="rbc-agenda-content" ref={contentRef}>
                            <table className="rbc-agenda-table">
                                <tbody ref={tbodyRef}>
                                    {range.map((day, idx) =>
                                        renderDay(day, eventsAg, idx)
                                    )}
                                </tbody>
                            </table>
                        </div>
                    </React.Fragment>
                ) : (
                    <span className="rbc-agenda-empty">
                        {messages.noEventsInRange}
                    </span>
                )}
            </div>
            <div className={"popup" + " " + visibleAg}>
                <p>{responseAg[1]}</p>
            </div>
        </>
    );
}

CustomAgenda.propTypes = {
    eventsAg: PropTypes.array,
    date: PropTypes.instanceOf(Date),
    length: PropTypes.number.isRequired,

    selected: PropTypes.object,

    accessors: PropTypes.object.isRequired,
    components: PropTypes.object.isRequired,
    getters: PropTypes.object.isRequired,
    localizer: PropTypes.object.isRequired
};

CustomAgenda.defaultProps = {
    length: 30
};

CustomAgenda.range = (start, { length = CustomAgenda.defaultProps.length }) => {
    let end = dates.add(start, length, "day");
    return { start, end };
};

CustomAgenda.navigate = (
    date,
    action,
    { length = CustomAgenda.defaultProps.length }
) => {
    switch (action) {
        case navigate.PREVIOUS:
            return dates.add(date, -length, "day");

        case navigate.NEXT:
            return dates.add(date, length, "day");

        default:
            return date;
    }
};

CustomAgenda.title = (
    start,
    { length = CustomAgenda.defaultProps.length, localizer }
) => {
    let end = dates.add(start, length, "day");
    return localizer.format({ start, end }, "agendaHeaderFormat");
};

export default CustomAgenda;
