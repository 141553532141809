import examplePic1 from "../../../images/articleExamples/examplePic1.jpg";
import examplePic2 from "../../../images/articleExamples/examplePic2.jpg";
import examplePic3 from "../../../images/articleExamples/examplePic3.jpg";

export const exampleBlogArticles = [
            {
                index: 1,
                pic: examplePic1,
                alt: "viki",
                title: 'EXAMPLE BOLG ARTICLE 1',
                author: "Ringer Viktória",
                text: "Nunc quis venenatis nulla. Nunc in eros atNunc quis venenatis nulla. Nunc ios at urna mollis viverra ut sit amet nibh. Suspendisse eu vehicula mi, vel commodo arcu. Nam metus ipsum, imperdiet aliquet est et, viverra iaculis risus. Curabitur sit amet pretium diam. Fusce sed nibh tincidunt, commodo lorem ut, cursus ex. Duis posuere erat non maximus ultricies. Aliquam elit mi, gravida sed suscipit sit amet, ornare sed risus. Ut pharetra nibh urna, ac fermentum ligula tempor interdum. Nulla vestibulum volutpat metus sit amet dictum.",
                date: "2019.07.16"
            },
            {
                index: 2,
                pic: examplePic2,
                alt: "zsani",
                title: 'EXAMPLE BOLG ARTICLE 2 YEAH',
                name: "Hornyák Zsanett",
                text: "rcu. Nam metus ipsum, imperdiet aliquet est et, viverra iaculis risus. Curabitur sit amet pretium diam. Fusce sed nibh tincidunt, commodo lorem ut, cursus ex. Duis posuere erat non maximus ultricies. Aliquam elit mi, gravida sed suscipit sit amet, ornare sed risus. Ut pharetra nibh urna, ac fermentum ligula tempor interdum. Nulla vestibulum volutpat metus sit amet dictum.",
                date: "2019.07.18"
            },
            {
                index: 3,
                pic: examplePic3,
                alt: "gabor",
                title: 'EXAMPLE BOLG ARTICLE 3 SUPER YEAH HELLO',
                name: "Kovács Gábor",
                text: "Integer rhoncus maximus scelerisque. Nunc eu eros in tellus mattis luctus. Proin vestibulum risus at erat porttitor venenatis. Duis urna enim, ornare nec rhoncus non, rutrum eu mi. Donec tristique sollicitudin felis, a interdum dui pretium quis. Duis sit amet orci et est posuere malesuada. Maecenas id sapien lacus. Donec feugiat leo at ipsum dictum, sit amet semper ex euismod. Phasellus eget nibh facilisis, venenatis risus nec, sagittis tortor. Pellentesque porta sollicitudin dolor eget cursus.",
                date: "2019.07.19"
            }
        ];



export const exampleNews =
        [
            {
                index: 1,
                pic: examplePic3,
                alt: "viki",
                title: 'ÓRAREND VÁLTOZÁS',
                author: "Ringer Viktória",
                text: "Nunc quis us ipsumincidunt, commodo lorem ut, cursus ex. Duis posuere erat non maximus ultricies. Aliquam elit mi, gravida sed suscipit sit amet, ornare sed risus. Ut pharetra nibh urna, ac fermentum ligula tempor interdum. Nulla vestibulum volutpat metus sit amet dictum.",
                date: "2019.09.4"
            },
            {
                index: 2,
                pic: examplePic1,
                alt: "zsani",
                title: 'MOSTANTÓL LAZÍTÓ FRISSÍTŐ MASSZÁSSAL IS',
                name: "Hornyák Zsanett",
                text: "arcu. Nam metus ipsum, imperdiet aliquet est et, viverra iaculis risus. Curabitur sit amet pretium diam. Fusce sed nibh tincidunt, commodo lorem ut, cursus ex. Duis posuere erat non maximus ultricies. Aliquam elit mi, gravida sed suscipit sit amet, ornare sed risus. Ut pharetra nibh urna, ac fermentum ligula tempor interdum. Nulla vestibulum volutpat metus sit amet dictum.",
                date: "2019.09.5"
            },
            {
                index: 3,
                pic: examplePic2,
                alt: "gabor",
                title: 'DUPLA KÖRENDZÉS MINDEN HÉTEN JÚLIUS 11-TŐL',
                name: "Kovács Gábor",
                text: "Integer rhoncus maximus scelerisque. Nunc eu eros in tellus mattis luctus. Proin vestibulum risus at erat porttitor venenatis. Duis urna enim, ornare nec rhoncus non, rutrum eu mi. Donec tristique sollicitudin felis, a interdum dui pretium quis. Duis sit amet orci et est posuere malesuada. Maecenas id sapien lacus. Donec feugiat leo at ipsum dictum, sit amet semp",
                date: "2019.09.6"
            }
        ];
