import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import axios from "axios";

import TimeTable from "./timetable.component";

const TimeTableInit = () => {
    const apiPath = window?.location?.origin;

    const getSetRes = (url, setter, data) => {
        axios
            .get(url)
            .then(response => {
                setter(response.data[data]);
            })
            .catch(error => {
                console.log(error);
            });
    };

    return (
        <TimeTable
            apiPath={apiPath}
            getSetRes={getSetRes}
        />
    );
};

if (document.getElementById("szim-timetable")) {
    ReactDOM.render(<TimeTableInit />, document.getElementById("szim-timetable"));
}

export default TimeTableInit;
