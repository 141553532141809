import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import axios from "axios";

import "./timetable-card.styles.scss";

const TimeTableCard = ({ selectedEvent, handleReservation }) => {
    let startHoursPure = selectedEvent.start.getHours();
    let startHours =
        startHoursPure <= 9 ? "0" + startHoursPure : startHoursPure;

    let startMinutesPure = selectedEvent.start.getMinutes();
    let startMinutes =
        startMinutesPure <= 9 ? "0" + startMinutesPure : startMinutesPure;

    let endHoursPure = selectedEvent.end.getHours();
    let endHours = endHoursPure <= 9 ? "0" + endHoursPure : endHoursPure;

    let endMinutesPure = selectedEvent.end.getMinutes();
    let endMinutes =
        endMinutesPure <= 9 ? "0" + endMinutesPure : endMinutesPure;

    return (
        <div className={"overview"}>
            <table>
                <thead>
                    <tr>
                        <th
                            colSpan="2"
                            className="c-szim-blue font-os-heavy bold-text content-card-title"
                        >
                            {selectedEvent.title}
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td className="content-card-text">
                            {!selectedEvent.waiting_list_free_places
                                ? "Szabad helyek"
                                : "Várakozólista helyek"}
                        </td>
                        <td className="content-card-text bold-text">
                            {!selectedEvent.waiting_list_free_places
                                ? selectedEvent.free_spaces
                                : selectedEvent.waiting_list_free_places}
                        </td>
                    </tr>
                    <tr>
                        <td className="content-card-text">Terem</td>
                        <td className="content-card-text bold-text">
                            {selectedEvent.room}
                        </td>
                    </tr>
                    <tr>
                        <td className="content-card-text">Oktató</td>
                        <td className="content-card-text bold-text">
                            {selectedEvent.instructor}
                        </td>
                    </tr>
                    <tr>
                        <td className="content-card-text"></td>
                        <td className="content-card-text bold-text"></td>
                    </tr>
                </tbody>
                <tfoot>
                    <tr>
                        <td className="content-card-text">
                            <b>
                                {startHours} {":"} {startMinutes}
                            </b>
                        </td>
                        <td className="content-card-text">
                            <b>
                                {endHours} {":"} {endMinutes}
                            </b>
                        </td>
                    </tr>
                </tfoot>
            </table>
            <span
                onClick={() => {
                    handleReservation();
                }}
                className={`sbtn ${
                    selectedEvent.status[0] === 3 ?
                        "sbtn-danger" : (selectedEvent.status[0] === 2 ?
                            "sbtn-warning" : "sbtn-primary")}`}
            >
                {selectedEvent.status[1]}
            </span>
        </div>
    );
};

if (document.getElementById("timetable-card")) {
    ReactDOM.render(
        <TimeTableCard />,
        document.getElementById("timetable-card")
    );
}

export default TimeTableCard;
