import React from "react";

export default function useDeviceDetect() {
  const [isMobile, setMobile] = React.useState(false);

  const [width, setWidth] = React.useState(0);

  React.useEffect(() => {
    const updateWindowDimensions = () => {
      const newWidth = window.innerWidth;
      setWidth(newWidth);
      console.log("updating width");
    };

    window.addEventListener("resize", updateWindowDimensions);

    return () => window.removeEventListener("resize", updateWindowDimensions)

  }, []);


  React.useEffect(() => {
    const mobile = window.innerWidth < 1024 ? true : false

    window.addEventListener('resize', () => {
      setMobile(mobile);
    });
    window.addEventListener('orientationchange', () => {
      setMobile(mobile);
    });

    setMobile(mobile);
  }, [width]);

  return { isMobile };
}
