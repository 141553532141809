import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import Carousel from "nuka-carousel";
import { useInView } from "react-hook-inview";

import {
    bannerPics,
    logoPic
} from "./szim_dynamic_banner.example.data";
import "./szim-dynamic-banner.styles.scss";

const SzimDynamicBanner = ({ banners }) => {
    const bannersObj = JSON.parse(banners);
    const [currCar, setCurrCar] = useState({ slideIndex: 0 });

    const [scrollMenu, setScrollMenu] = useState("");

    const handleScroll = React.useCallback(event => {
        setScrollMenu(window.scrollY > 400 ? true : false);
    });

    useEffect(() => {
        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll, false);
        };
    }, [scrollMenu]);


    useEffect(() => {
        document
            .getElementById("subpage-top-nav")
            .classList.add("switch-off-top-nav");
    });

    const addBlueAnimatedCircleToButton = () => {
        const elementList = document.getElementsByClassName(
            "szim-carousel-button-container"
        );
        for (let element of elementList) {
            element.classList.add("szim-carousel-button-container-hovers");
        }
        setTimeout(function () {
            for (let element of elementList) {
                element.classList.remove(
                    "szim-carousel-button-container-hovers"
                );
            }
        }, 3500);
    };

    const removeBlueAnimatedCircleToButton = () => {
        const elementList = document.getElementsByClassName(
            "szim-carousel-button-container"
        );
        setTimeout(function () {
            for (let element of elementList) {
                element.classList.remove(
                    "szim-carousel-button-container-hovers"
                );
            }
        }, 50);
    };

    return (
        <>
            <div className="container szim-carousel-container">
                <div className="row szim-carousel-container-top-row">
                    <div className="col-xl-7">
                        <div className="row szim-dynamic-banner-top-row d-none d-xl-flex">
                            <div className="col szim-dynamic-banner-logo-pic-container no-padding d-none d-xl-block">
                                <img
                                    className="szim-dynamic-banner-logo-pic"
                                    src={logoPic.logoPic}
                                    alt="logo"
                                />
                                <img
                                    className="szim-dynamic-banner-logo-pic szim-dynamic-banner-logo-sm-pic d-xl-none"
                                    src={logoPic.logoPic}
                                    alt="logo"
                                />
                            </div>
                            <div className="col szim-dynamic-banner-link-container d-none d-xl-block">
                                <a className="fs-12 szim-dynamic-banner-link-dot fw-600" href="/egyeni-kezelesek">EGYÉNI KEZELÉSEK</a>
                                <a className="fs-12 szim-dynamic-banner-link-dot fw-600" href="/csoportos-orak">CSOPORTOS ÓRÁK</a>
                                <a className="fs-12 szim-dynamic-banner-link-dot fw-600" href="/orarend">ÓRAREND</a>
                                <a className="fs-12 szim-dynamic-banner-link-dot fw-600" href="/araink">ÁRAINK</a>
                                <a className="fs-12 szim-dynamic-banner-link-dot fw-600" href="/munkatarsaink">MUNKATÁRSAINK</a>
                            </div>
                        </div>
                        <div className="row justify-content-start">
                            <div
                                className="col-2 col-md-1 szim-carousel-button-container"
                                onChange={addBlueAnimatedCircleToButton}
                            >
                                <div className="row">
                                    <div className="col">
                                        <button
                                            className="szim-carousel-button szim-carousel-button-animations spin circle"
                                            onClick={function (slideIndex) {
                                                removeBlueAnimatedCircleToButton();
                                                setCurrCar({
                                                    slideIndex:
                                                        currCar.slideIndex != 0
                                                            ? currCar.slideIndex - 1
                                                            : 6
                                                });
                                            }}
                                        >
                                            ❮
                                    </button>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col">
                                        <button
                                            className="szim-carousel-button szim-carousel-button-animations spin circle"
                                            onClick={function (slideIndex) {
                                                removeBlueAnimatedCircleToButton();
                                                setCurrCar({
                                                    slideIndex:
                                                        currCar.slideIndex != 6
                                                            ? currCar.slideIndex + 1
                                                            : 0
                                                });
                                            }}
                                        >
                                            ❯
                                    </button>
                                    </div>
                                </div>
                            </div>
                            <Carousel
                                className="col c-white szim-dynamic-banner-carousel"
                                slideIndex={currCar.slideIndex}
                                afterSlide={slideIndex =>
                                    setCurrCar({ slideIndex })
                                }
                                withoutControls={true}
                                transitionMode="scroll"
                                heightMode={"max"}
                                wrapAround={true}
                                autoplayInterval={3000}
                                autoplay={true}
                                vertical={true}
                                onDragStart={removeBlueAnimatedCircleToButton}
                                beforeSlide={addBlueAnimatedCircleToButton}
                                pauseOnHover={true}
                            >
                                {bannersObj.map(data => (
                                    <div
                                        key={data.id}
                                        className="carousel-text-container font-os-light"
                                    >
                                        <span className="fs-24 bold-text">
                                            {data.title}
                                        </span>
                                        <br />
                                        <span className="szim-dynamic-banner-text fs-18">
                                            {data.description}
                                        </span>
                                        <br />
                                        <span className="szim-dynamic-banner-line">
                                            &nbsp;
                                    </span>
                                        <br />
                                        <a
                                            href={data.cta_1_url}
                                            className="sbtn sbtn-tertiary"
                                        >
                                            <span>{data.cta_1_caption}</span>
                                        </a>
                                    </div>
                                ))}
                            </Carousel>
                        </div>
                    </div>
                    <div className="col-5 szim-dynamic-banner-bg d-none d-xl-block">
                        <img
                            src={bannerPics.backgroundPic}
                            alt=""
                            className="szim-dynamic-banner-background-pic"
                        />
                        <img
                            src={bannerPics.frontPic}
                            alt=""
                            className="szim-dynamic-banner-front-pic"
                        />
                    </div>
                </div>
            </div>
            {scrollMenu ?
                <div id="subpage-top-nav" className={scrollMenu ? "fixed container-fluid" : "container-fluid"}>
                    <div className="row">
                        <div className="col">
                            <div className="container-xl">
                                <div className="row szim-dynamic-banner-top-row">
                                    <div className="col szim-dynamic-banner-logo-pic-container no-padding">
                                        <a href="/"><img className="szim-dynamic-banner-logo-pic" src="../images/szimmetria_logo_no_text.png" alt="logo" /></a>
                                    </div>
                                    <div className="col szim-dynamic-banner-link-container d-none d-xl-block">
                                        <a className="fs-12 szim-dynamic-banner-link-dot fw-600" href="/egyeni-kezelesek">EGYÉNI KEZELÉSEK</a>
                                        <a className="fs-12 szim-dynamic-banner-link-dot fw-600" href="/csoportos-orak">CSOPORTOS ÓRÁK</a>
                                        <a className="fs-12 szim-dynamic-banner-link-dot fw-600" href="/orarend">ÓRAREND</a>
                                        <a className="fs-12 szim-dynamic-banner-link-dot fw-600" href="/araink">ÁRAINK</a>
                                        <a className="fs-12 szim-dynamic-banner-link-dot fw-600" href="/munkatarsaink">MUNKATÁRSAINK</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                : ""
            }
        </>
    );
};

if (document.getElementById("szim-dynamic-banner")) {
    const component = document.getElementById("szim-dynamic-banner");
    const props = Object.assign({}, component.dataset);
    ReactDOM.render(
        <SzimDynamicBanner {...props} />,
        document.getElementById("szim-dynamic-banner")
    );
}

export default SzimDynamicBanner;
